<template>
  <div class="content-wrapper">
    <PageHeader :screenName="'Editar ' + form.nome" :linkItems="linkItems" />

    <ConfirmModal
      itemIdentifier="colaborador"
      :modalShow="modal.showModal"
      :typeModal="modal.typeModal"
      @confirmed="redirectToList"
      @canceled="closeModalConfirm"
    />

    <ActionColaboradorModal
      itemIdentifier="colaborador"
      :modalShow="modalAction.showModal"
      :typeModal="modalAction.typeModal"
      @confirmed="desvincularColaborador"
      @canceled="closeModalAction"
    />

    <SucessoModal
      :modalShow="modalSucesso.showModal"
      :typeModal="modalSucesso.typeModal"
      :caseModal="modalSucesso.caseModal"
      @confirmed="redirectToList"
    />

    <ErrorModal
      :modalShow="modalError.showModal"
      :typeModal="modalError.typeModal"
      :caseModal="modalError.caseModal"
      @confirmed="redirectToList"
    />
    
    <ModalShowError
        :modalShow="modalColaboradorError.showModal"
        :typeModal="modalColaboradorError.typeModal"
        :infoText="modalColaboradorError.infoText"
        @confirmed="closeModalColaborador"
    />

    <main class="card">
      <HeaderEmpresa :empresa="empresa" @confirmed="resolveRoute" />
      <section class="form p-2 mt-2">
        <CustomAlert
          variant="warning"
          :show="!editavel"
          message="Você não tem autorização para editar este colaborador"
        />
        <validation-observer ref="colaboradorRules">
          <b-form>
            <b-row>
              <b-col lg="4" md="4" sm="12">
                <b-form-group label="CPF*">
                  <validation-provider
                    #default="{ errors }"
                    name="cpf"
                    rules="required"
                  >
                    <b-form-input
                      :state="
                        errors.length > 0 ||
                        form.errorApi.cpf ||
                        form.errorApi.error
                          ? false
                          : null
                      "
                      id="cpf-input"
                      v-model="form.cpf"
                      v-mask="'###.###.###-##'"
                      placeholder="000.000.000-00"
                      maxLength="14"
                      :disabled="true"
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="form.errorApi.cpf" class="text-danger">
                      {{ form.errorApi.cpf }}
                    </small>
                    <small v-if="form.errorApi.error" class="text-danger">
                      {{ form.errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                <b-form-group label="Data de nascimento*">
                  <validation-provider
                    #default="{ errors }"
                    name="data_nascimento"
                  >
                    <b-input-group>
                      <vue-cleave
                        id="data-nascimento-input"
                        v-model="input.data_nascimento"
                        :raw="false"
                        :options="option.data"
                        class="form-control"
                        placeholder="00/00/0000"
                        name="data_nascimento"
                        autocomplete="off"
                        :disabled="!editavel"
                        @input="setNascimentoInput"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          locale="pt-BR"
                          button-only
                          right
                          :hide-header="true"
                          v-model="form.data_nascimento"
                          :max="today"
                          class="form-control d-flex align-items-center"
                          label-help="Selecione a data de nascimento"
                          button-variant="custom-blue"
                          clearable
                          :disabled="!editavel"
                          @input="setNascimentoPicker"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.data_nascimento"
                      class="text-danger"
                    >
                      {{ form.errorApi.data_nascimento }}
                    </small>
                    <small v-if="form.errorApi.error" class="text-danger">
                      {{ form.errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                <b-form-group label="Nome completo do colaborador*">
                  <validation-provider
                    #default="{ errors }"
                    name="nome"
                    rules="required"
                  >
                    <b-form-input
                      :state="
                        errors.length > 0 ||
                        form.errorApi.nome ||
                        form.errorApi.error
                          ? false
                          : null
                      "
                      id="nome-input"
                      v-model="form.nome"
                      placeholder="John Doe"
                      :disabled="!editavel"
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="form.errorApi.nome" class="text-danger">
                      {{ form.errorApi.nome }}
                    </small>
                    <small v-if="form.errorApi.error" class="text-danger">
                      {{ form.errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4" md="4" sm="12">
                <b-form-group label="Nome da mãe">
                  <validation-provider #default="{ errors }" name="nome_mae">
                    <b-form-input
                      :state="
                        errors.length > 0 || form.errorApi.nome_mae
                          ? false
                          : null
                      "
                      id="nome_mae-input"
                      v-model="form.nome_mae"
                      :disabled="!editavel"
                      placeholder="Nome da mãe"
                    />
                    <small v-if="form.errorApi.nome_mae" class="text-danger">
                      {{ form.errorApi.nome_mae }}
                    </small>
                    <small v-if="form.errorApi.error" class="text-danger">
                      {{ form.errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                <validation-provider #default="{ errors }" name="genero">
                  <b-form-group
                    label="Sexo*"
                    :state="
                      errors.length > 0 || form.errorApi.genero ? false : null
                    "
                  >
                    <v-select
                      id="genero"
                      v-model="form.genero"
                      :options="generoSelect"
                      label="topico"
                      :disabled="!editavel"
                      placeholder="Selecione uma opção"
                      :clearable="true"
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="form.errorApi.genero" class="text-danger">
                      {{ form.errorApi.genero }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                <validation-provider
                  #default="{ errors }"
                  name="categoria"
                  required
                >
                  <b-form-group
                    label="Categoria*"
                    :state="
                      errors.length > 0 || form.errorApi.categoria
                        ? false
                        : null
                    "
                  >
                    <v-select
                      id="categoria"
                      v-model="form.categoria"
                      :options="categoriaSelect"
                      label="descricao"
                      placeholder="Selecione uma categoria"
                      :clearable="true"
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="form.errorApi.categoria" class="text-danger">
                      {{ form.errorApi.categoria }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4" md="4" sm="12">
                <NacionalidadeField
                  :idNacionalidade="form.id_nacionalidade"
                  @updateNacionalidade="updateNacionalidade"
                  :errorApiIdNacionalidade="form.errorApi.id_nacionalidade"
                  customClass="edit-colaborador"
                />
              </b-col>
              <b-col lg="4" md="4" sm="12">
                <b-form-group label="Matrícula">
                  <validation-provider #default="{ errors }" name="matricula">
                    <b-form-input
                      :state="
                        errors.length > 0 ||
                        form.errorApi.matricula ||
                        form.errorApi.error
                          ? false
                          : null
                      "
                      id="matricula-input"
                      v-model="form.matricula"
                      placeholder="Identificação interna"
                    />
                    <small v-if="form.errorApi.matricula" class="text-danger">
                      {{ form.errorApi.matricula }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="4" sm="12">
                <b-form-group label="Setor">
                  <validation-provider #default="{ errors }" name="setor">
                    <b-form-input
                      :state="
                        errors.length > 0 ||
                        form.errorApi.setor ||
                        form.errorApi.error
                          ? false
                          : null
                      "
                      id="setor-input"
                      v-model="form.setor"
                      placeholder="Área, setor ou departamento"
                    />
                    <small v-if="form.errorApi.setor" class="text-danger">
                      {{ form.errorApi.setor }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </section>
      <section class="buttons p-2">
        <b-button
          id="save-colaborador"
          class="mr-2 mb-2 mb-sm-0"
          variant="custom-blue"
          @click.prevent="validationForm(true)"
          :disabled="saving"
        >
          <feather-icon icon="CheckIcon" class="mr-50" />
          <span class="align-middle">Salvar colaborador</span>
          <span
            v-if="saving"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          ></span>
        </b-button>
        <b-button
          id="save-new-colaborador"
          class="mr-2 mb-2 mb-sm-0"
          variant="outline-warning"
          @click.prevent="openModalAction"
          :disabled="saving"
        >
          <feather-icon icon="CheckIcon" class="mr-50" />
          <span class="align-middle">Desvincular colaborador</span>
        </b-button>
        <b-button
          id="cancel-register-colaborador"
          :disabled="saving"
          variant="outline-danger"
          @click.prevent="openModalConfirm"
        >
          <feather-icon icon="XIcon" class="mr-50" />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </section>
    </main>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import moment from "moment";
import TimedAlert from "@/views/components/custom/timed-alert/TimedAlert.vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import ActionColaboradorModal from "@/views/components/custom/modals/ActionsColaboradorModal.vue";
import ConfirmModal from "@/views/components/custom/modals/ConfirmModal.vue";
import SucessoModal from "@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue";
import ErrorModal from "@/views/components/custom/modals/ErroIdInexistenteModal.vue";
import HeaderEmpresa from "@/views/components/custom/page-header/Header-empresa.vue";
import VueCleave from "vue-cleave-component";
import handleErrorsService from "./Services/HandleErrors";
import CustomAlert from "@/views/components/custom/timed-alert/CustomAlert.vue";
import NacionalidadeField from "@/views/components/custom/field-select-nacionalidade/NacionalidadeField.vue";
import ModalShowError from '@/views/components/custom/modals/ModalShowError.vue';

export default {
  title: "Editar colaborador",
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    TimedAlert,
    PageHeader,
    ConfirmModal,
    SucessoModal,
    ActionColaboradorModal,
    BFormDatepicker,
    BCardText,
    HeaderEmpresa,
    ErrorModal,
    BInputGroup,
    BInputGroupAppend,
    VueCleave,
    CustomAlert,
    NacionalidadeField,
    ModalShowError
  },
  data() {
    return {
      linkItems: [
        {
          name: "Empresa",
          routeName: "empresa-colaborador-list",
        },
        {
          name: "Carregando dados...",
          active: true,
        },
        {
          name: "Editar colaborador",
          active: true,
        },
      ],
      modal: {
        showModal: false,
        typeModal: "danger",
      },
      modalAction: {
        showModal: false,
        typeModal: "warning",
      },
      modalSucesso: {
        showModal: false,
        typeModal: "success",
        caseModal: "edicao",
      },
      modalError: {
        showModal: false,
        typeModal: "error",
        caseModal: "idInexistente",
      },
      modalColaboradorError: {
        showModal: false,
        typeModal: "erro-colaborador",
        infoText: ""
      },
      required,
      savedAlert: false,
      saving: false,
      savingAndNew: false,
      today: moment().format("YYYY-MM-DD"),
      option: {
        data: {
          date: true,
          delimiter: "/",
          datePattern: ["d", "m", "Y"],
        },
        cpf: {
          numericOnly: true,
          uppercase: true,
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
        },
      },
      form: {
        id_pessoa_fisica: "",
        cpf: "",
        nome: "",
        genero: "",
        data_nascimento: "",
        categoria: "",
        ano: "",
        setor: "",
        matricula: "",
        nome_mae: "",
        id_nacionalidade: null,
        errorApi: {
          cpf: "",
          nome: "",
          genero: "",
          data_nascimento: "",
          id_categoria: "",
          setor: "",
          matricula: "",
          error: "",
          nome_mae: "",
          id_nacionalidade: "",
        },
      },
      input: {
        data_nascimento: "",
      },
      generoSelect: [],
      categoriaSelect: [],
      empresa: {
        id_empresa: "",
        nome: "",
      },
      editavel: true,
    };
  },
  mounted() {
    if (this.$route.params.idPessoa) {
      this.preenchimentoFormulario();
    } else {
      return this.openModalError();
    }

    this.editavel = this.$route.params.editavel;

    this.$http.get(this.$api.getEmpresaCategoria()).then(({ data }) => {
      this.categoriaSelect = data;
    });
    this.generoSelect = ["Masculino", "Feminino"];
    this.preenchimentoFormulario();
  },
  methods: {
    validationForm(redirectToList) {
      this.$refs.colaboradorRules.validate().then((success) => {
        if (success) {
          if (redirectToList) {
            this.saving = true;
          } else {
            this.savingAndNew = true;
          }
          this.editColaborador(redirectToList);
        }
      });
    },
    editColaborador(redirectToList) {
      const parameters = {
        id_pessoa_fisica: this.form.id_pessoa_fisica,
        cpf: this.formatCpf(this.form.cpf),
        nome: this.form.nome,
        genero: this.form.genero,
        data_nascimento: this.form.data_nascimento,
        ano: this.form.ano,
        id_empresa: this.empresa.id_empresa,
        id_categoria: this.form.categoria
          ? this.form.categoria.id_categoria
          : "",
        setor: this.form.setor,
        matricula: this.form.matricula,
        nome_mae: this.form.nome_mae,
        id_nacionalidade: this.form.id_nacionalidade,
      };

      this.$http
        .put(this.$api.colaborador(), parameters)
        .then(() => {
          if (redirectToList) {
            this.openModalSucesso();
          } else {
            this.savedAlert = !this.savedAlert;
          }

          this.saving = false;
          this.savingAndNew = false;
          this.cleanForm();
        })
        .catch(({ response: { data } }) => {
          handleErrorsService.handleErrosColaborador(data, this.form.errorApi);

          this.saving = false;
          this.savingAndNew = false;
        });
    },
    cleanForm() {
      this.form.cpf = "";
      this.form.nome = "";
      this.form.genero = "";
      this.form.data_nascimento = "";
      this.input.data_nascimento = "";
      this.form.categoria = "";
      this.form.matricula = "";
      this.form.setor = "";

      this.form.errorApi.cpf = "";
      this.form.errorApi.nome = "";
      this.form.errorApi.genero = "";
      this.form.errorApi.data_nascimento = "";
      this.form.errorApi.categoria = "";
      this.form.errorApi.matricula = "";
      this.form.errorApi.setor = "";

      this.$refs.colaboradorRules.reset();
    },

    preenchimentoFormulario() {
      this.form.id_pessoa_fisica = this.$route.params.idPessoa;
      this.form.cpf = this.$route.params.cpf;
      this.form.nome = this.$route.params.nome;
      this.form.genero = this.$route.params.genero;
      this.form.data_nascimento = this.$route.params.data_nascimento;
      this.form.categoria = this.$route.params.categoria;
      this.form.ano = this.$route.params.ano;
      this.editavel = this.$route.params.editavel;
      this.form.matricula = this.$route.params.matricula;
      this.form.setor = this.$route.params.setor;
      this.form.nome_mae =
        this.$route.params.nome_mae != null
          ? this.$route.params.nome_mae
          : null;
      this.form.id_nacionalidade = this.$route.params.id_nacionalidade;
      this.form.descricao_nacionalidade =
        this.$route.params.descricao_nacionalidade != null
          ? this.$route.params.descricao_nacionalidade
          : "-";

      if (this.$route.params.empresa) {
        this.empresa = this.$route.params.empresa;
        this.linkItems[1].name = this.empresa.nome;
      } else {
        this.redirectToList();
      }
      this.setNascimentoPicker();
    },
    setNascimentoPicker() {
      this.input.data_nascimento = moment(this.form.data_nascimento).format(
        "DD/MM/YYYY"
      );
    },
    setNascimentoInput() {
      this.form.data_nascimento = moment(
        this.input.data_nascimento,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
    },

    redirectToList() {
      this.$router.push({ name: "empresa-colaborador-list" });
    },
    openModalConfirm() {
      this.modal.showModal = true;
    },
    closeModalConfirm() {
      this.modal.showModal = false;
    },
    openModalAction() {
      this.modalAction.showModal = true;
    },
    closeModalAction() {
      this.modalAction.showModal = false;
    },
    openModalError() {
      this.modalError.showModal = true;
    },
    openModalSucesso() {
      this.modalSucesso.showModal = true;
    },
    openModalColaboradorError(msg) {
      this.closeModalAction()
      this.modalColaboradorError.infoText = msg
      this.modalColaboradorError.showModal = true
      this.isChangingModal = false;  
    },
    closeModalColaborador() {
      this.modalColaboradorError.showModal = false;
    },
    formatCpf(cpf) {
      return cpf.replace(/\D/g, "");
    },
    resolveRoute(route) {
      if (route != this.$route.name) {
        this.$router.push({
          name: route,
          params: {
            empresa: this.empresa,
          },
        });
      }
    },
    desvincularColaborador() {
      const parameters = {
        id_pessoa_fisica: this.form.id_pessoa_fisica,
        id_empresa: this.empresa.id_empresa,
        ano: this.form.ano,
      };
      this.$http.patch(this.$api.colaborador(), parameters).then(() => {
        this.redirectToList();
      })
      .catch(({ response }) => {
        if(response.status == 400) {
          this.modalError.showModal = false
          this.openModalColaboradorError(response.data.errors)
        }
        this.saving = false;
        this.savingAndNew = false;
      });;
    },
    updateNacionalidade(id_nacionalidade) {
      this.form.id_nacionalidade = id_nacionalidade;
    },
  },
};
</script>